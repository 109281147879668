// SLIDER

section.fullscreen {
  width: 100%;
  height: 95%;
  overflow: hidden;
  position: relative;
  border-bottom: none;

  .fullscreen-slider {
    position: absolute;
    overflow: hidden;
    background-position: center center;
    background-repeat: repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }

  .slick-slide {
    > div {
      height: 100%;
      width: 100%;
      display: block;

      > div {
        height: 100%;
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        > .container {
          position: relative;
          z-index: 1;
        }

        &:after {
          background: -moz-radial-gradient(center,ellipse cover,transparent 0,rgba(0,0,0,.35) 100%);
          background: -webkit-gradient(radial,center center,0,center center,100%,color-stop(0%,transparent),color-stop(100%,rgba(0,0,0,.35)));
          background: -webkit-radial-gradient(center,ellipse cover,transparent 0,rgba(0,0,0,.35) 100%);
          background: -o-radial-gradient(center,ellipse cover,transparent 0,rgba(0,0,0,.35) 100%);
          background: -ms-radial-gradient(center,ellipse cover,transparent 0,rgba(0,0,0,.35) 100%);
          background: radial-gradient(ellipse at center,transparent 0,rgba(0,0,0,.35) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=1);
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;      
        }        
      }
    }
  }

  .slick-dots {
    @media (max-width: $width-small) {
      display: none !important;
    }
  }

  p {
    color: $color-inverted;
    opacity: 0.5;
  }

  h3 {
    font-family: $font-title;
    font-weight: 300;
    font-style: normal;
    font-size: 48px;
    line-height: 40px;
    margin: 0 0 20px 0;
    color: $color-inverted;
    position: relative;
  }

  h4 {
    color: $color-text;
    font-size: 32px;
    line-height: 32px;
  }

  .container {
    height: 100%;

    .col-sm-12 {
      display: flex;
      flex-direction: column;
    }
  }

  .row {
    height: 100%;
    display: flex;
    align-items: center;

    &.align-top {
      align-items: flex-start;
      padding-top: 100px;
    }
  }

  @media (max-width: $width-xlarge) {
    height: 700px;

    .row {
      &.align-top {
        padding-top: 50px;
      }
    }

    h3 {
      font-size: 48px;
      line-height: 40px;      
      margin-bottom: 10px;
    }
  }

  @media (max-width: $width-large) {
    height: 500px;

    .row {
      &.align-top {
        padding-top: 40px;
      }
    }

    p {
      margin-bottom: 10px;
    }

    h3 {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 24px;
      line-height: 24px;
    }
  }

  @media (max-width: $width-medium) {
    height: 800px;

    .slick-slide {
      > div {
        > div {
          background-position: bottom center;
        }
      }
    }

    .row {
      &.align-top {
        padding-top: 20px;
      }
    }

    p {
      margin-bottom: 10px;
    }

    h3 {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 24px;
      line-height: 24px;
    }
  }

  @media (max-width: 630px) {
    height: 600px;
  }

  @media (max-width: $width-small) {
    height: 480px;
  }
}
