// QUICK LINKS

section.quick-links {
  transition: $transition-general;
  background-color: $color-brand;
  position: fixed;
  padding: 10px 30px;
  box-shadow: 0 -10px 20px rgba(0,0,0,0.4);
  left: 0;
  bottom: -80px;
  border: none;
  z-index: 2;

  .button {
    background-color: transparent;
    color: $color-inverted;
  }

  &.headroom--not-top {
    bottom: 0;
  }

  // &.headroom--bottom {
  //   bottom: -80px;
  // }

  p {
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
  }

  @media (max-width: $width-medium) {
    display: block;
  }
}
