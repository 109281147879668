// BRANDS

section.brands {
  padding-bottom: 150px;
  background-color: $color-inverted;

  h2 {
    margin-bottom: 40px;

    @media (max-width: $width-small) {
      margin-bottom: 20px;
    }
  }

  .brands-slider {
    img {
    }

    .slick-slide {
      div {
        padding: 10px;
      }
    }
  }

  .slider-arrow {
    &.slider-next {
      right: -40px;
    }

    &.slider-prev {
      left: -40px;
    }
  }

  .slick-dots {
    bottom: -60px;

    li {
      button {
        &:after {
          background-color: transparentize($color-text, 0.9);
          box-shadow: none;
        }
      }

      &.slick-active {
        button {
          &:after {
            background-color: $color-secondary;
          }
        }
      }
    }

    @media (max-width: $width-small) {
      display: none;
    }
  }

  @media (max-width: $width-small) {
    padding-bottom: 50px;
  }
}
