// GENERAL

html,
body {
  height: 100%;
  min-height: 100%;
  font-family: $font-text;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.1px;
  background-color: $color-inverted;
  color: $color-text;
  position: relative;
  background-color: #F4F4F4;
}

.svg-definitions {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

section {
  width: 100%;
  overflow: hidden;
  padding: 100px 0;
  box-shadow: 0 -5px 20px rgba(0,0,0,0.05);
  border-bottom: 1px solid #FAFAFA;

  @media (max-width: $width-small) {
    padding: 50px 0;
  }
}

.bleed {
  padding: 0;
}

a,
a:link,
a:active,
a:visited {
  transition: $transition-general;
  text-decoration: none;
  color: $color-text;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $color-secondary;
  }
}

h1 {
  font-family: $font-title;
  font-weight: 400;
  font-style: normal;
  font-size: 80px;
  line-height: 80px;
  margin: 0 0 20px 0;
  color: $color-inverted;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

h2 {
  font-family: $font-title;
  font-weight: 300;
  font-style: normal;
  font-size: 48px;
  line-height: 40px;
  margin: 0 0 20px 0;
  color: $color-brand;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $width-medium) {
    font-size: 38px;
    line-height: 38px;
    margin-bottom: 4px;
  }
}

h3 {
  font-family: $font-title;
  font-weight: 300;
  font-style: normal;
  font-size: 34px;
  line-height: 36px;
  margin: 0 0 10px 0;
  color: $color-secondary;

  a,
  a:link,
  a:active,
  a:visited {
    color: $color-inverted;

    &:hover,
    &:focus {
      color: $color-secondary;
    }
  }
}

p {
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.1px;
  margin: 0 0 30px 0;
  padding: 0;

  strong {
    font-weight: 400;
  }

  & + h2 {
    margin-top: 60px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {}
}

.slider-arrow {
  transition: $transition-general;
  position: absolute;
  top: 50%;
  z-index: 1;
  cursor: pointer;
  height: 50px;
  width: 40px;
  margin-top: -25px;
  opacity: 0.4;
  display: none;

  &.slider-next {
    right: 15px;
  }

  &.slider-prev {
    left: 15px;
  }

  &:hover {
    opacity: 1;
  }
}

.slick-dots {
  position: absolute;
  text-align: center;
  bottom: 20px;
  width: 100%;
  z-index: 2;
  font-size: 0;
  line-height: 0;
  height: 30px;

  li {
    display: inline-block;
    height: 30px;
    font-size: 0;
    line-height: 0;

    button {
      background: none;
      border: none;
      font-size: 0;
      display: block;
      width: 30px;
      height: 30px;
      padding: 0;
      margin: 0 10px;
      outline: none;
      position: relative;
      box-shadow: none;
      cursor: pointer;

      &:after {
        content: '';
        transition: all 0.2s linear;
        background-color: transparentize($color-inverted, 0.7);
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
        border-radius: 100px;
      }
    }

    &.slick-active {
      button {
        &:after {
          background-color: $color-secondary;
          width: 14px;
          height: 14px;
          top: 8px;
          left: 8px;
        }
      }
    }
  }
}

button,
.button,
a.button {
  @include backface-visibility-fix;
  transition: all 1200ms cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-family: $font-title;
  font-weight: 300;
  font-size: 26px;
  line-height: 26px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 13px 20px 9px 20px;
  display: inline-block;
  margin: 0;
  border-radius: 0px;
  vertical-align: bottom;
  width: auto;
  background: $color-inverted;
  color: $color-secondary;
  border-radius: 2px;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background-color: $color-inverted;
      color: $color-secondary;
      box-shadow: none;
      transform: none;
    }
  }
  
  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: 0 7px 14px rgba(0,0,0,0.1), 0 5px 5px rgba(0,0,0,0.1);
    transform: perspective(100px) translateZ(7px) !important;
  }

  svg {
    width: 26px;
    height: 26px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}
