// VIDEO

section.video {
  background-color: $color-secondary;

  img {
    max-width: 100%;
    height: auto;
  }

  video {
    width: 1144px;
    max-width: 100%;
    height: auto;
  }

  @media (max-width: $width-medium) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
