// GENERAL

body.offers,
body.offers-list {
  .footer {
    padding: 20px 20px;
    margin-top: 40px;
    border-top: 1px solid #AAAAAA;
    color: #AAAAAA;
    text-align: center;
    font-size: 14px;
    line-height: 22px;

    a {
      color: #AAAAAA;

      &:hover {
        color: #444444;
      }
    }
  }

  .page-content {
    padding-top: 150px;

    h2 {
      margin-bottom: 20px; 
    }

    @media (max-width: $width-medium) {
      padding-top: 100px;
    }
  }

  .thumb-wrapper {
    @include backface-visibility-fix;
    transition: $transition-general;
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: block;
    margin-bottom: 30px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1), 0 0 0 1px rgba(0,0,0,0.05);
    border: 5px solid #FFFFFF;

    &:hover {
      box-shadow: 0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
      transform: perspective(100px) translateZ(7px);
    }

    @media (max-width: $width-large) {
      height: 320px;
    }

    @media (max-width: $width-medium) {
      height: 220px;
    }

    @media (max-width: $width-small) {
      height: 150px;
    }

    @media (max-width: $width-xsmall) {
      height: 100%;

      &:hover {
        box-shadow: none;
        transform: none;
        cursor: default;
      }
    }
  }

  .thumb {
    width: 100%;
    height: auto;
  }

  .modal-body {
    padding: 10px;
  }

  .modal-content {
    border-radius: 0;
  }

  .close {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 2;
    opacity: 1;
    width: 50px;
    height: 50px;

    &:after,
    &:before {
      content: '';
      width: 30px;
      height: 2px;
      background-color: #FFFFFF;
      display: block;
      position: absolute;
      top: 50%;
      left: 10px;
      margin-top: -1px;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:hover {
      opacity: 1;
    }

    @media (max-width: $width-medium) {
      background-color: #000000;
      border-radius: 50%;
    }
  }

  .carousel-control {
    background: none !important;
    position: fixed;
    opacity: 1;
    width: 60px;
    height: 80px;
    top: 50%;
    margin-top: -40px;

    &:after {
      content: '';
      width: 30px;
      height: 30px;
      border-top: 3px solid #FFFFFF;
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -15px;
      z-index: 1;
    }

    &.left {
      &:after {
        left: 20px;
        border-left: 3px solid #FFFFFF;
        transform: rotate(-45deg);
      }
    }

    &.right {
      &:after {
        right: 20px;
        border-right: 3px solid #FFFFFF;
        transform: rotate(45deg);
      }
    }

    @media (max-width: $width-medium) {
      display: none;
    }
  }

  .carousel-inner > .item > img {
    width: 100%;
  }

  .carousel-fade {
    .carousel-inner {
      .item {
        transition-property: opacity;
        transition: none;
      }
    }
  }

  .col-xs-6 {
    @media (max-width: $width-xsmall) {
      width: 100%;
    }
  }

  .lists {
    .list-item {
      margin-bottom: 15px;
      display: inline-block;
      font-weight: 400;

      &:before {
        content: '●';
        padding-right: 10px;
      }
    }

    .new {
      &:after {
        content: 'NUEVO';
        background-color: #EA0000;
        padding: 4px 8px;
        margin-left: 8px;
        border-radius: 2px;
        color: #FFFFFF;
      }
    }

    .update {
      &:after {
        content: 'ACTUALIZADO';
        background-color: #08C1C1;
        padding: 4px 8px;
        margin-left: 8px;
        border-radius: 2px;
        color: #FFFFFF;
      }
    }    
  }
}

footer {
  background-color: $color-text;
  padding: 30px 0;
  text-align: center;

  img,
  svg {
    width: 30px;
    height: 22px;
  }
}
