// NAVIGATION

nav.navigation {
  transition: $transition-general;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
  padding-top: 20px;
  padding-bottom: 20px;

  .background {
    transition: $transition-general;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    opacity: 0;
  }

  .col {
    line-height: 0;
    font-size: 0; 
  }

  a {
    outline: none;
  }

  .logo {
    transition: $transition-general;
    height: 40px;
    width: 136px;
    opacity: 0;

    @media (max-width: $width-medium) {
      height: 20px;
      width: 68px;
    }
  }

  ul {
    font-size: 0;
    line-height: 0;

    li {
      display: inline-block;
      margin-left: 40px;

      a,
      a:link,
      a:active,
      a:visited {
        transition: $transition-general;
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        padding: 0;
        outline: none;
        color: $color-inverted;
        text-transform: uppercase;

        &:hover,
        &:focus {
          background: none;
          outline: none;
          color: $color-secondary;
        }
      }

      &.active {
        a,
        a:link,
        a:active,
        a:visited {
          color: $color-inverted;
        }
      }

      @media (max-width: $width-large) {
        margin-left: 30px;
      }      
    }

    @media (max-width: $width-medium) {
      display: none;
    }
  }

  &.headroom--not-top,
  &.always-visible {
    box-shadow: 0 5px 20px rgba(0,0,0,0.1);

    .background {
      opacity: 1;
    }

    .logo {
      opacity: 1;
    }

    ul {
      li {
        a,
        a:link,
        a:active,
        a:visited {
          color: lighten($color-text, 65%);

          &:hover,
          &:focus {
            color: $color-secondary;
          }
        }

        &.active {
          a,
          a:link,
          a:active,
          a:visited {
            color: $color-secondary;
          }
        }
      }
    }
  }
}
