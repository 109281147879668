// HERO

section.hero {
  width: 100%;
  height: 95%;
  overflow: hidden;
  position: relative;
  border-bottom: none;

  .hero-slider {
    position: absolute;
    overflow: hidden;
    background-position: center center;
    background-repeat: repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }

  .slick-slide {
    > div {
      height: 100%;
      width: 100%;
      display: block;

      > div {
        height: 100%;
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }      
    }
  }

  .slick-dots {
    @media (max-width: $width-medium) {
      display: none !important;
    }
  }

  h1 {
    text-shadow: 0 3px 20px rgba(0,0,0,0.2);
    display: block;
    padding: 0 0 20px 0;
    margin-bottom: 30px;

    &:after {
      content: '';
      width: 150px;
      height: 1px;
      position: absolute;
      background-color: $color-inverted;
      display: block;
      bottom: 0;
      right: 50%;
      margin-right: -75px;
      right: auto;
      left: 0;
      margin: 0;
      opacity: 0.5;
    }

    @media (max-width: $width-medium) {
      font-size: 60px;
      line-height: 50px;
    }
  }

  h2 {
    color: $color-inverted;
    text-shadow: 0 3px 20px rgba(0,0,0,0.4);
  }

  .container {
    height: 100%;

    .col-sm-12 {
      display: flex;
      flex-direction: column;
    }
  }

  .row {
    height: 100%;
    display: flex;
    align-items: center;
  }

  @media (max-width: 1320px) {
    height: 800px;
  }

  @media (max-width: $width-xlarge) {
    height: 700px;
  }

  @media (max-width: $width-large) {
    height: 500px;

    h1 {
      font-size: 70px;
    }

    h2 {
      font-size: 42px;
      line-height: 34px;
    }
  }

  @media (max-width: $width-medium) {
    height: 400px;

    h1 {
      font-size: 60px;
    }

    h2 {
      font-size: 36px;
      line-height: 30px;
    }

    .hero-slider {
      &:after {
        background-color: rgba(0,0,0,0.1);
      }
    }    
  }

  @media (max-width: $width-small) {
    height: 260px;
    overflow: visible;
    margin-bottom: 150px;

    .container {
      height: 150px;
      background-color: $color-inverted;
      bottom: -150px;
      width: 100%;
      position: absolute;
      box-shadow: 0 4px 4px rgba(0,0,0,0.1);

      > .row {
        display: block;

        > .col-xs-12 {
          padding: 0;
        }
      }
    }

    h1 {
      font-size: 50px;
      line-height: 80px;
      width: 100%;
      margin: 0;
      text-align: center;
      padding: 0;
      margin-bottom: 10px;
      color: $color-brand;
      text-shadow: none;

      &:after {
        width: 100%;
        background-color: #DDD;
      }
    }

    h2 {
      font-size: 24px;
      line-height: 24px;
      margin: 0;
      text-align: center;
      color: $color-brand;
      text-shadow: none;
    }

    .slick-slide > div > div {
      background-position: bottom right !important;
    }
  }  
}
