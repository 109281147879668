// COMPANY

section.company {
  @media (max-width: $width-medium) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .company-slider {
    @media (max-width: $width-medium) {
      margin-top: 30px;
    }
  }
}
